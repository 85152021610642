<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          เพิ่ม System Admin
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- <h3 class="mb-7">
          <i class="bi bi-person-fill" style="font-size: 20px"></i>
          ผู้ขาย / ผู้รับเงิน
        </h3> -->

        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >Username</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.username"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="amount" class="required form-label col-sm-10"
            >Email</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.email"
              @blur="checkDupEmail()"
              class="form-control"
              name="amount"
              id="amount"
              required
            />
            <div v-if="isDupUser" class="text-end pb-3" style="color: #f1416c">
              <span>E-mail ถูกใช้งานไปแล้ว</span>
            </div>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="docNumber" class="form-label col-sm-10">Password</label>
          <div class="col-sm-10">
            <div style="position: relative">
              <input
                v-model="form.password"
                @input.prevent="passwordChange(form.password)"
                :type="showPassword ? 'text' : 'password'"
                id="password"
                class="form-control"
                name="docNumber"
                maxlength="16"
                required
              />
              <button
                type="button"
                class="fadeIn third"
                @click.prevent="showPassword = !showPassword"
                id="btnPass"
                @mouseover="colorEyePass = 'color: white;'"
                @mouseleave="colorEyePass = 'color: grey;'"
                style="
                  cursor: pointer;
                  position: absolute;
                  top: 15%;
                  right: 3%;
                  border-radius: 55%;
                  border-color: transparent;
                "
              >
                <i class="far fa-eye" :style="colorEyePass"></i>
              </button>
            </div>

            <div class="row d-flex justify-space-between">
              <div class="col-4 text-start ps-4 fadeIn third">
                <span>{{ counterPass }} / 16</span>
              </div>
              <div
                v-if="textPassError.length"
                class="col-8 text-end pe-4"
                style="color: #f1416c"
              >
                <div v-for="item in textPassError" :key="item">
                  {{ item.err }}
                </div>
              </div>
            </div>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="docNumber" class="form-label col-sm-10"
            >Confirm Password</label
          >
          <div class="col-sm-10">
            <div style="position: relative">
              <input
                v-model="form.confirmPassword"
                @input.prevent="confirmPasswordChange(form.confirmPassword)"
                :type="showConfirmPassword ? 'text' : 'password'"
                id="password"
                class="form-control"
                name="docNumber"
                maxlength="16"
                :disabled="passwordEmpty"
                :style="!passwordEmpty ? '' : 'background-color: lightGrey'"
                required
              />
              <button
                type="button"
                class="fadeIn third"
                @click.prevent="showConfirmPassword = !showConfirmPassword"
                :disabled="passwordEmpty"
                id="btnConfirmPass"
                @mouseover="colorEyeConfirmPass = 'color: white;'"
                @mouseleave="colorEyeConfirmPass = 'color: grey;'"
                style="
                  cursor: pointer;
                  position: absolute;
                  top: 15%;
                  right: 3%;
                  border-radius: 55%;
                  border-color: transparent;
                "
              >
                <i class="far fa-eye" :style="colorEyeConfirmPass"></i>
              </button>
            </div>

            <div class="row d-flex justify-space-between">
              <div class="col-4 text-start ps-4 fadeIn third">
                <span>{{ counterConfirmPass }} / 16</span>
              </div>
              <div class="col-8 text-end pe-4" style="color: #f1416c">
                <span v-if="textConfirmError">{{ textConfirmError }}</span>
              </div>
            </div>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="docNumber" class="form-label col-sm-10"
            >รหัสอนุมัติเอกสาร</label
          >
          <div class="col-sm-10">
            <div style="position: relative">
              <input
                v-model="form.approvalPassword"
                @input="approvalPasswordChange(form.approvalPassword)"
                :type="showApprovalPassword ? 'text' : 'password'"
                maxlength="6"
                class="form-control"
                name="docNumber"
                id="docNumber"
                required
              />
              <button
                type="button"
                class="fadeIn third"
                @click.prevent="showConfirmPassword = !showConfirmPassword"
                :disabled="passwordEmpty"
                id="btnConfirmPass"
                @mouseover="colorEyeConfirmPass = 'color: white;'"
                @mouseleave="colorEyeConfirmPass = 'color: grey;'"
                style="
                  cursor: pointer;
                  position: absolute;
                  top: 15%;
                  right: 3%;
                  border-radius: 55%;
                  border-color: transparent;
                "
              >
                <i class="far fa-eye" :style="colorEyeConfirmPass"></i>
              </button>
            </div>

            <div class="row d-flex justify-space-between">
              <div class="col-4 text-start ps-4 fadeIn third">
                <span>{{ counterApprovalPass }} / 6</span>
              </div>
              <div class="col-8 text-end pe-4" style="color: #f1416c">
                <span v-if="textApprovalError">{{ textApprovalError }}</span>
              </div>
            </div>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="docNumber" class="form-label col-sm-10">First Name</label>
          <div class="col-sm-10">
            <input
              v-model="form.firstName"
              type="text"
              class="form-control"
              name="docNumber"
              id="docNumber"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="docNumber" class="form-label col-sm-10">Last Name</label>
          <div class="col-sm-10">
            <input
              v-model="form.lastName"
              type="text"
              class="form-control"
              name="docNumber"
              id="docNumber"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="docNumber" class="form-label col-sm-10"
            >Phone Number</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.telNumber"
              type="number"
              class="form-control"
              name="docNumber"
              id="docNumber"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import purchaseApi from "@/api/purchase/";
import loginApi from "@/api/user/";

export default {
  setup() {
    document.title = "BELUCA | เพิ่มผู้ใช้งาน";
  },
  data: () => ({
    loading: false,
    isSubmit: false,

    typePass: "password",
    showPassword: false,
    showConfirmPassword: false,
    showApprovalPassword: false,
    isValid: false,

    companyItems: [],

    passwordEmpty: true,

    counterPass: 0,
    textPassError: [],

    counterConfirmPass: 0,
    textConfirmError: "",

    counterApprovalPass: 0,
    textApprovalError: "",

    isDupUser: false,
    responseDupErr: "",

    form: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      telNumber: "",
      type: "",
      role: "SYSTEM ADMIN",
      companyId: null,
      statusActive: true,

      // companyId: "",

      approvalPassword: "",
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    colorEyePass: "color: grey;",
    colorEyeConfirmPass: "color: grey;",
  }),

  methods: {
    async submit() {
      let addResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      if (this.isDupUser) {
        Swal.fire({
          icon: "error",
          title: this.responseDupErr,
          confirmButtonText: "ตกลง",
          timer: 4000,
        });
      }

      if (this.isValid && !this.isDupUser) {
        this.loading = true;
        delete this.form.confirmPassword;

        try {
          addResponse = await loginApi.register.addUser(this.form);
          if (addResponse.data) {
            if (addResponse.response_status === "SUCCESS") {
              Swal.fire({
                icon: "success",
                title: "เพิ่มสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push({
                  path: "/setting/user-profile",
                  query: {},
                });
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "เพิ่มไม่สำเร็จ !",
              showConfirmButton: false,
              timer: 1500,
            });
            this.loading = false;
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    checkFormIsEmpty() {
      !this.form.username
        ? (this.isValid = false)
        : !this.form.password
        ? (this.isValid = false)
        : !this.form.approvalPassword
        ? (this.isValid = false)
        : this.form.password.length < 8
        ? (this.isValid = false)
        : !this.form.confirmPassword
        ? (this.isValid = false)
        : this.form.confirmPassword.length < 8
        ? (this.isValid = false)
        : this.form.confirmPassword !== this.form.password
        ? (this.isValid = false)
        : !this.form.email
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/setting/user-profile",
        query: {},
      });
    },

    passwordChange(val) {
      if (val) {
        this.counterPass = val.length;

        this.validatePass(val);
        // }
      } else {
        this.counterPass = 0;
        this.passwordEmpty = true;
      }
    },

    validatePass(val) {
      const number = /[0-9]/;
      const lowerCase = /[a-z]/;
      const upperCase = /[A-Z]/;

      let haveNumber = false;
      let haveLowerCase = false;
      let haveUpperCase = false;
      let haveLength = false;

      if (val.length < 8) {
        haveLength = false;
        this.passwordEmpty = true;
        this.textConfirmError = "";
        this.form.confirmPassword = "";
        this.counterConfirmPass = 0;
      } else {
        haveLength = true;
      }
      for (let i = 0; i < val.length; i++) {
        if (number.test(val[i])) {
          haveNumber = true;
        }

        if (lowerCase.test(val[i])) {
          haveLowerCase = true;
        }

        if (upperCase.test(val[i])) {
          haveUpperCase = true;
        }
      }
      // }

      if (!haveLength) {
        const findItem = this.textPassError.find((data) => data.id === 1);

        findItem
          ? ""
          : this.textPassError.push({
              err: "รหัสผ่านต้องมีความยาว 8 - 16 หลัก",
              id: 1,
            });
      } else {
        const findItem = this.textPassError.find((data) => data.id === 1);

        if (findItem) {
          this.textPassError = this.textPassError.filter(
            (data) => data.id != findItem.id
          );
        }
      }

      if (!haveNumber) {
        const findItem = this.textPassError.find((data) => data.id === 2);

        findItem
          ? ""
          : this.textPassError.push({
              err: "รหัสต้องประกอบไปด้วยตัวเลข (0 - 9)",
              id: 2,
            });
      } else {
        const findItem = this.textPassError.find((data) => data.id === 2);

        if (findItem) {
          this.textPassError = this.textPassError.filter(
            (data) => data.id != findItem.id
          );
        }
      }

      if (!haveLowerCase) {
        const findItem = this.textPassError.find((data) => data.id === 3);

        findItem
          ? ""
          : this.textPassError.push({
              err: "รหัสต้องมีอักษรภาษาอังกฤษ พิมพ์เล็ก (a - z)",
              id: 3,
            });
      } else {
        const findItem = this.textPassError.find((data) => data.id === 3);

        if (findItem) {
          this.textPassError = this.textPassError.filter(
            (data) => data.id != findItem.id
          );
        }
      }

      if (!haveUpperCase) {
        const findItem = this.textPassError.find((data) => data.id === 4);

        findItem
          ? ""
          : this.textPassError.push({
              err: "รหัสต้องมีอักษรภาษาอังกฤษ พิมพ์ใหญ่ (A - Z)",
              id: 4,
            });
      } else {
        const findItem = this.textPassError.find((data) => data.id === 4);

        if (findItem) {
          this.textPassError = this.textPassError.filter(
            (data) => data.id != findItem.id
          );
        }
      }

      if (haveNumber && haveLowerCase && haveUpperCase && haveLength) {
        this.passwordEmpty = false;
      }
    },

    confirmPasswordChange(val) {
      if (val) {
        this.counterConfirmPass = val.length;

        if (val !== this.form.password) {
          this.textConfirmError = "กรุณากรอกให้ตรงกับ Password";
        } else {
          this.textConfirmError = "";
        }
      } else {
        this.counterConfirmPass = 0;
      }
    },

    approvalPasswordChange(val) {
      if (val) {
        this.counterApprovalPass = val.length;
        if (val.length < 6) {
          this.textApprovalError = "กรุณากรอกให้ครบ 6 หลัก";
          // clear validate confirmPass
          this.textConfirmError = "";
          this.counterApprovalPass = 0;
        } else {
          this.textApprovalError = "";
        }
      } else {
        this.counterApprovalPass = 0;
      }
    },

    async checkDupEmail() {
      const response = await loginApi.register.checkEmailUser({
        email: this.form.email,
      });

      if (response.response_status === "ERROR") {
        this.isDupUser = true;
        this.responseDupErr = response.message;
        Swal.fire({
          icon: "error",
          title: this.responseDupErr,
          confirmButtonText: "ตกลง",
          timer: 4000,
        });
      } else {
        this.isDupUser = false;
      }
    },
  },
};
</script>
<style scoped src="@/assets/custom-outside.css"></style>
